<template>
  <div data-app>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="menu-icon flaticon-clock-2"></i
        ><span class="ml-3">Time Tracker</span>
      </h3>
    </div>
    <div class="col-12">
      <div class="row float-right mb-15 mt-2" style="width: 100%">
        <div>
          <span
            v-if="
              selectedArray.length > 0 &&
              GetAuthenticatedUser.userType == 'Super Admin'
            "
            class="mr-2"
          >
            <v-btn @click="ApproveAllEntries"> Approved By Admin </v-btn>
          </span>
          <span
            v-if="
              selectedArray.length > 0 &&
              GetAuthenticatedUser.userType == 'Super Admin'
            "
          >
            <v-btn @click="ConfirmDeleteAll"> Delete </v-btn>
          </span>
        </div>
        <v-spacer></v-spacer>
        <label class="mr-5 pt-2" for="">Date Range : </label>

        <date-range-picker
          ref="picker"
          open="left"
          v-model="dates"
          :disabled="isdisable"
          :ranges="false"
          @toggle="logEvent('event: open', $event)"
          ><div slot="footer" slot-scope="data" class="slot">
            <div class="float-right mb-5">
              <span v-if="data.rangeText != ' - '">
                {{ data.rangeText }}
              </span>
              <span class="m-4">
                <a
                  @click="clearRange"
                  v-if="!data.in_selection"
                  class="btn btn-success btn-sm"
                  >Clear</a
                >
              </span>
              <span>
                <a
                  @click="data.clickCancel"
                  v-if="!data.in_selection"
                  class="btn btn-success btn-sm"
                  >Cancel</a
                >
              </span>
              <span class="m-4">
                <a
                  @click="data.clickApply"
                  v-if="!data.in_selection"
                  class="btn btn-success btn-sm"
                  >Apply</a
                >
              </span>
            </div>
          </div>
        </date-range-picker>
        <b-dropdown
          style="float: right; padding: 0px !important"
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          right
        >
          <template #button-content>
            <b-icon icon="calendar"></b-icon>
          </template>
          <b-dropdown-item @click="dateSelected(1)">Today</b-dropdown-item>
          <b-dropdown-item @click="dateSelected(2)">Yesterday</b-dropdown-item>
          <b-dropdown-item @click="dateSelected(3)"
            >Last 7 Days</b-dropdown-item
          >
          <b-dropdown-item @click="dateSelected(4)"
            >Last 30 Days</b-dropdown-item
          >
          <b-dropdown-item @click="dateSelected(5)">This month</b-dropdown-item>
          <b-dropdown-item @click="dateSelected(6)">Last Month</b-dropdown-item>
          <b-dropdown-item @click="dateSelected(7)"
            >Custom Range</b-dropdown-item
          >
        </b-dropdown>
        <!-- <div class="col-1">
          <v-btn @click="Search">Search</v-btn>
        </div> -->
        <div class="ml-15">
          <v-btn @click="filter"><i class="fas fa-filter filter"></i></v-btn>
        </div>
      </div>
    </div>
    <div
      v-if="!isfetching"
      class="container mt-15"
      style="background-color: #ffffff; overflow-x: scroll"
    >
      <b-table
        class="table table-bordered"
        show-empty
        :items="items"
        select-mode="single"
        :current-page="currentPage"
        :per-page="0"
        :fields="fields"
        selectable
        striped
        @row-selected="onRowSelected"
        hover
      >
        <template #head(id)="data">
          <span></span>
          <b-form-checkbox
            @change="selectedAll(items)"
            v-model="isAllSeclected"
          >
            Select All
          </b-form-checkbox>
        </template>

        <template #cell(id)="row">
          <b-form-checkbox
            @click="selectedId"
            :checked="selectAll"
            @change="selectedId(row.item)"
          >
          </b-form-checkbox>
        </template>

        <template v-slot:head(editStartTimeDate)>
          <span> {{ showheader() }} </span>
        </template>
        <template v-slot:head(editEndTimeDate) style="width: 20px">
          <span> {{ showheader() }} </span>
        </template>
        <template #cell(Action)="row">
          <a @click="Edit(row.item)" class="mr-1">
            <i class="fa fa-calendar" aria-hidden="true"></i>
          </a>
        </template>
        <template #cell(Client)="row">
          <a class="mr-1">
            {{ row.item.Client }}
          </a>
        </template>
        <template #cell(Caregiver)="row">
          <a class="mr-1">
            {{ row.item.Caregiver }}
          </a>
        </template>
        <template #cell(editStartTimeDate)="row">
          <span
            v-if="row.item.editStartTimeDate"
            class="badge bg-dark text-white"
            >{{ dobformat(row.item.editStartTimeDate) }} (A)</span
          >
        </template>
        <template #cell(startTimeDate)="row">
          {{
            dobformat(
              row.item.editStartTimeDate != null
                ? row.item.editStartTimeDate
                : row.item.startTimeDate
            )
          }}
        </template>
        <template #cell(editEndTimeDate)="row">
          <span v-if="row.item.editEndTimeDate" class="badge bg-dark text-white"
            >{{ dobformat(row.item.editEndTimeDate) }} (A)</span
          >
        </template>
        <template #cell(endTimeDate)="row">
          {{
            dobformat(
              row.item.editEndTimeDate != null
                ? row.item.editEndTimeDate
                : row.item.endTimeDate
            )
          }}
        </template>
        <template #cell(createdBy)="row">
          {{ row.item.createdBy }}
        </template>
        <template #cell(createdDate)="row">
          {{
            row.item.createdDate.substring(0, 4) != "0001"
              ? dobformat(row.item.createdDate)
              : ""
          }}
        </template>
        <template #cell(modifiedBy)="row">
          {{ row.item.modifiedBy }}
        </template>
        <template #cell(modifiedDate)="row">
          {{
            row.item.modifiedDate.substring(0, 4) != "0001"
              ? dobformat(row.item.modifiedDate)
              : ""
          }}
        </template>
        <template #cell(payRate)="row">
          {{ payRate(row.item.payRate) }}
        </template>
        <template #cell(status)="row">
          {{
            row.item.status.includes("(Paid)")
              ? "Paid"
              : row.item.status.split("(")[0]
          }}
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
            size="md"
            v-if="perPage != '-1'"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div class="page-title pageheading" style="height: 50px">
      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="htmlToCSV">
          <i class="material-icons">add</i> <span>Download CSV</span></a
        >
      </span>
    </div>
    <b-modal
      size="sm"
      left
      text
      id="filter"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div v-if="PayersList">
        <v-autocomplete
          v-model="payers"
          :items="payersList"
          item-text="companyName"
          item-value="id"
          label="Payers"
          persistent-hint
          multiple
          :search-input.sync="payerInput"
          @change="payerInput = ''"
        ></v-autocomplete>
      </div>
      <div v-if="ClientsList">
        <v-autocomplete
          v-model="clients"
          :items="ClientsList"
          item-text="name"
          item-value="id"
          label="Clients"
          persistent-hint
          multiple
          :search-input.sync="clientInput"
          @change="clientInput = ''"
        ></v-autocomplete>
      </div>
      <div v-if="careGiverList">
        <v-autocomplete
          v-model="caregivers"
          :items="careGiverList"
          item-text="name"
          item-value="id"
          label="Caregiver"
          multiple
          :search-input.sync="careGiverInput"
          @change="careGiverInput = ''"
        ></v-autocomplete>
      </div>
      <div v-if="serviceList">
        <v-autocomplete
          v-model="subcodes"
          :items="serviceList"
          item-text="subCode"
          item-value="id"
          label="Service Codes"
          multiple
          :search-input.sync="careGiverInput"
          @change="careGiverInput = ''"
        ></v-autocomplete>
      </div>
      <div>
        <v-autocomplete
          v-model="status"
          :items="statusdrpdown"
          label="Status "
          multiple
          :search-input.sync="statusInput"
          @change="statusInput = ''"
        ></v-autocomplete>
      </div>
      <div class="float-right">
        <span>
          <a @click="RESET" class="btn btn-primary btn-sm">Cancel</a>
        </span>
        <span class="m-4">
          <a @click="Apply" class="btn btn-primary btn-sm">Apply</a>
        </span>
      </div>
    </b-modal>
    <b-modal
      @close="updateData"
      size="xl"
      centered
      text
      id="Action"
      hide-footer
    >
      <timeManagement @updateData="updateData"> </timeManagement>
    </b-modal>
  </div>
</template>
<script>
import timeManagement from "../../components/timeManagement";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

import { fetchApi } from "../../core/services/request";
export default {
  components: { timeManagement, DateRangePicker },
  data() {
    return {
      dates: {
        startDate: null,
        endDate: null,
      },
      careGiverInput: "",
      clientInput: "",
      isAllSeclected: false,
      statusInput: "",
      payerInput: "",
      payers: [],
      clients: [],
      caregivers: [],
      subcodes: [],
      status: [],
      payersList: [],
      isfetching: false,
      currentPage: 1,
      perPage: 50,
      isdisable: true,
      totalItems: 0,
      items: [],
      CaregiverList: [],
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "clientName",
          label: "Client",
        },
        {
          key: "caregiverName",
          label: "Caregiver",
        },
        {
          key: "startTimeDate",
          label: "Time Start",
        },
        {
          key: "endTimeDate",
          label: "Time End",
        },
        {
          key: "companyName",
          label: "Payor",
        },
        {
          key: "authorizationNumber",
          label: "Auth #",
        },
        {
          key: "actualHours",
          label: "Hours",
        },
        // {
        //   key: "payableHours",
        //   label: "Payable Hours",
        // },

        {
          key: "payRate",
          label: "Pay Rate",
        },

        {
          key: "subCode",
          label: "Service Code",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "createdBy",
          label: "Created By",
        },
        {
          key: "createdDate",
          label: "Created Date",
        },
        {
          key: "modifiedBy",
          label: "Modified By",
        },
        {
          key: "modifiedDate",
          label: "Modified Date",
        },
        {
          key: "Action",
          label: "Action",
        },
      ],

      selectAll: false,
      selectedArray: [],
      statusdrpdown: [
        { value: "Approved - Signature", text: "Approved - Signature" },
        { value: "Needs Client Approval", text: "Needs Client Approval" },
        { value: "Approved By Admin", text: "Approved By Admin" },
        { value: "In Progress", text: "In Progress" },
        { value: "Manual Entry", text: "Manual Entry" },
        { value: "Paid)", text: "Paid" },
      ],
    };
  },

  watch: {
    dates: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
  },
  created() {},
  computed: {
    ...mapGetters([
      "timeTracker",
      "totalTimeTracker",
      "PayersList",
      "ClientsList",
      "careGiverList",
      "serviceList",
      "GetAuthenticatedUser",
    ]),
  },
  mounted() {
    // this.dates = {
    //   startDate: new Date().setDate(new Date().getDate() - 30),
    //   endDate: new Date().setDate(new Date().getDate() + 1),
    // };
    this.dates = {
      startDate: new Date(),
      endDate: new Date(),
    };
    this.$store.dispatch("getPayersList").then((res) => {
      if (this.GetAuthenticatedUser.userType == "JFS") {
        this.payersList = this.PayersList.filter((x) => x.companyName == "JFS");
        
        this.payers = this.payersList[0].id;
      } else if (this.GetAuthenticatedUser.userType == "Home Health") {
        this.payersList = this.PayersList.filter(
          (x) => x.companyName == "Home Health"
        );
        this.payers = this.payersList[0].id;
      } else {
        this.payersList = this.PayersList;
      }
    });

    this.$store.dispatch("getClientList");
    this.$store.dispatch("getCareGiverList");
    this.$store.dispatch("getSubCodes");

    this.fetchData().catch((error) => {
      console.error(error);
    });
    this.statusdrpdown = this.statusdrpdown.sort((a, b) =>
      a.value > b.value ? 1 : -1
    );
  },
  methods: {
    ApproveAllEntries() {
      Swal.fire({
        icon: "warning",
        text: "Are you sure you want to approve all entries",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
      }).then((e) => {
        if (e.isConfirmed) {
          this.changeStatus("Approved By Admin");
        }
      });
    },
    ConfirmDeleteAll() {
      Swal.fire({
        icon: "warning",
        text: "Are you sure you want to delete selected records",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
      }).then((e) => {
        if (e.isConfirmed) {
          this.changeStatus("Delete");
        }
      });
    },
    async changeStatus(obj) {
      await this.$store.dispatch("updateBulkEntries", {
        authId: this.selectedArray,
        status: obj,
      });
      this.selectedArray = [];
      await this.fetchData();
    },
    selectedAll(data) {
      this.selectedArray = [];
      if (data && this.isAllSeclected) {
        this.items.forEach((x) => {
          this.selectedArray.push(x.id);
        });
      }

      if (this.items.length == this.selectedArray.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    selectedId(data) {
      if (
        this.selectedArray.length == 0 ||
        !this.selectedArray.includes(data.id)
      ) {
        this.selectedArray.push(data.id);
      } else {
        this.selectedArray.splice(data.id, 1);
      }
    },

    logEvent() {},
    htmlToCSV() {
      var filename = "Time Tracker";
      var data = [];

      var header = `"Client","Caregiver","Time Start","Time End","Payer","Auth #","Hours","Pay Rate","Service Code","Status"`;
      data.push(header);
      this.timeTracker.forEach((t) => {
        var row = `"${t.clientName}","${t.caregiverName}","${
          t.editStartTimeDate != null
            ? `${t.editStartTimeDate.split("T")[0]} ${
                t.editStartTimeDate.split("T")[1].split(".")[0]
              }`
            : t.startTimeDate != null
            ? `${t.startTimeDate.split("T")[0]} ${
                t.startTimeDate.split("T")[1].split(".")[0]
              }`
            : ""
        }","${
          t.editEndTimeDate != null
            ? `${t.editEndTimeDate.split("T")[0]} ${
                t.editEndTimeDate.split("T")[1].split(".")[0]
              }`
            : t.endTimeDate != null
            ? `${t.endTimeDate.split("T")[0]} ${
                t.endTimeDate.split("T")[1].split(".")[0]
              }`
            : ""
        }","${t.companyName}","${t.authorizationNumber}","${t.actualHours}","${
          t.payRate
        }","${t.subCode}","${t.status}"`;
        data.push(row);
      });
      this.downloadCSVFile(data.join("\n"), filename);
    },
    downloadCSVFile(csv, filename) {
      var csv_file, download_link;

      csv_file = new Blob([csv], { type: "text/csv" });

      download_link = document.createElement("a");

      download_link.download = filename;

      download_link.href = window.URL.createObjectURL(csv_file);

      download_link.style.display = "none";

      document.body.appendChild(download_link);

      download_link.click();
    },
    dateSelected(obj) {
      if (obj == 1) {
        this.dates = {
          startDate: new Date(),
          endDate: new Date(),
        };
      }
      if (obj == 2) {
        var date = new Date();
        date.setDate(date.getDate() - 1);
        this.dates = {
          startDate: date,
          endDate: date,
        };
      }
      if (obj == 3) {
        var sdate = new Date();
        sdate.setDate(sdate.getDate() - 7);
        this.dates = {
          startDate: sdate,
          endDate: new Date(),
        };
      }
      if (obj == 4) {
        var today = new Date();
        var priorDate = new Date(new Date().setDate(today.getDate() - 30));
        this.dates = {
          startDate: priorDate,
          endDate: today,
        };
      }
      if (obj == 5) {
        var date = new Date();
        this.dates = {
          startDate: new Date(date.getFullYear(), date.getMonth(), 1),
          endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
        };
      }
      if (obj == 6) {
        var date = new Date();

        this.dates = {
          startDate: new Date(date.getFullYear(), date.getMonth() - 1, 1),
          endDate: new Date(date.getFullYear(), date.getMonth(), 0),
        };
      }
      if (obj == 7) {
        this.isdisable = false;
        this.$refs.picker.togglePicker(true);
      }
    },
    clearRange() {
      this.dates = {
        startDate: null,
        endDate: null,
      };
    },
    showheader() {
      return "";
    },
    Search() {
      this.fetchData();
    },
    filter() {
      this.$bvModal.show("filter");
    },
    dobformat(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear() +
          " " +
          date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
        );
      }
    },
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    updateData() {
      this.fetchData();
      this.$bvModal.hide("Action");
    },
    Apply() {
      this.fetchData();
      this.$bvModal.hide("filter");
    },
    RESET() {
      this.payers = [];
      this.clients = [];
      this.caregivers = [];
      this.status = [];
      if (this.GetAuthenticatedUser.userType == "JFS") {
        this.payersList = this.PayersList.filter((x) => x.companyName == "JFS");
         this.payers = this.payersList[0].id;
      } else if (this.GetAuthenticatedUser.userType == "Home Health") {
        this.payersList = this.PayersList.filter(
          (x) => x.companyName == "Home Health"
        );
        this.payers = this.payersList[0].id;
      } else {
        this.payersList = this.PayersList;
      }
      this.fetchData();
      this.$bvModal.hide("filter");
    },
   async onRowSelected(items) {
        await fetchApi(`/api/TimeTracker/GetById?Id=${items[0].id}`)
        .then((response) => {
          this.Edit(response.data.output[0]);
        }) 
    },
    Edit(obj) {
      debugger 
      if (obj != undefined) {
        debugger
        this.$store
          .dispatch("getSubCodesById", {
            caregiverId: obj.caregiverId.toString(),
            clientId: obj.clientId.toString(),
            reqFromWeb: true,
          })
          .then(() => {
            this.$store.dispatch("editTimeTaker", obj);
            this.$bvModal.show("Action");
          });
      }
    },
    payRate(obj) {
      if (obj) {
        return "$" + " " + obj;
      } else {
        return null;
      }
    },
    async fetchData() {
      this.isfetching = true;
      this.selectedArray = [];
      var startDate;
      var endDate;
      if (this.dates.startDate) {
        startDate = this.dobformat(this.dates.startDate);
      }
      if (this.dates.endDate) {
        endDate = this.dobformat(this.dates.endDate);
      }
      await this.$store
        .dispatch("getTimeTracker", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          payers: this.payers,
          clients: this.clients,
          caregivers: this.caregivers,
          status: this.status,
          startdate: startDate,
          enddate: endDate,
          subcodes: this.subcodes,
        })
        .then((response) => {
          this.isfetching = false;
          this.items = this.timeTracker; 
          this.totalItems = this.totalTimeTracker;
        })
        .catch((ex) => {
          this.isfetching = false;
        });
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style scoped>
.text-span {
  background: black;
  color: white;
  border-radius: 50%;
  line-height: 26px;
  display: inline-block;
  text-align: center;
  padding: 6px;
}
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.table thead th {
  width: 5% !important;
}
.filter:hover {
  color: #1b5d77 !important;
}
</style>
