<template>
  <div>
    <h2>VIEW/EDIT TIME ENTRY</h2>
    <span
      >View/Edit this time entry. You can change the time/date,and service,and
      approve pending time entries.</span
    >
    <v-row>
      <div class="form-group col-md-6 mb-form">
        <label>Time Start </label>
        <date-picker
          ref="dp1"
          @focusin.native="onfocusin"
          v-model="form.editStartTimeDate"
          placeholder="Choose Start Date"
          :config="ReminderPickerOptions"
        ></date-picker>
        <span class="float-right" v-if="form.editStartTimeDate">
          <small>Actual : {{ form.startTimeDate }}</small></span
        >
      </div>
      <div class="form-group col-md-6 mb-form">
        <label>Time End </label>
        <date-picker
          ref="dp1"
          class="dpdataEndDate"
          @focusin.native="onfocusin"
          v-model="form.editEndTimeDate"
          placeholder="Choose End Date"
          :config="ReminderPickerOptions"
        ></date-picker>
        <span v-if="form.endTimeDate" class="float-right">
          <small>Actual : {{ form.endTimeDate }}</small>
        </span>
      </div>
    </v-row>
    <v-row>
      <div class="form-group col-md-6 mb-form">
        <label>GPS at Clock-In </label>
        <br />
        <a
          id="linkbtnAddress"
          class="font-blue link"
          target="_blank"
          :href="'https://maps.google.com/?q=' + form.clockInGPS"
        >
          {{ form.clockInGPS }}
        </a>
        <!-- <v-text-field
          label="GPS at Clock-In "
          disabled
          v-model="form.clockInGPS"
          maxlength="50"
        ></v-text-field> -->
      </div>
      <div class="form-group col-md-6 mb-form">
        <label>GPS at Clock-Out </label>
        <br />
        <a
          id="linkbtnAddress"
          class="font-blue link"
          target="_blank"
          :href="'https://maps.google.com/?q=' + form.clockOutGPS"
        >
          {{ form.clockOutGPS }}
        </a>
        <!-- <v-text-field
          label="GPS at Clock-Out "
          disabled
          v-model="form.clockOutGPS"
          maxlength="50"
        ></v-text-field> -->
      </div>
    </v-row>
    <v-row>
      <div class="form-group col-md-6 mb-form">
        <label> Client </label>
        <v-text-field
          label="Client"
          disabled
          v-model="form.clientName"
          maxlength="50"
        ></v-text-field>
      </div>
      <div class="form-group col-md-3 mb-form">
        <label>Caregiver </label>
        <v-text-field
          label="Caregiver"
          disabled
          v-model="form.caregiverName"
          maxlength="50"
        ></v-text-field>
      </div>
      <div class="form-group col-md-3 mb-form">
        <br />
        <span>
          <v-btn
            :disabled="form.status != 'Needs Client Approval'"
            class="btn btn-success mr-3 text-white"
            @click="changeStatus"
          >
            {{ form.status }}
          </v-btn>
          <br />
          <small v-if="!isApproved"> Click to Approve</small>
        </span>
      </div>
    </v-row>
    <v-row>
      <!-- <div class="form-group col-md-6 mb-form">
        <label>Service</label>
        <model-list-select
          disabled
          :list="subcodeListClient"
          v-model="subCodeId"
          option-value="id"
          option-text="subCode"
          placeholder="select Sub Code"
        >
        </model-list-select>
      </div> -->
      <div class="form-group col-md-6 mb-form">
        <label>Service</label>
        <v-text-field
          label="Service"
          disabled
          v-model="form.subCode"
          maxlength="50"
        ></v-text-field>
      </div>
      <div class="form-group col-md-3 mb-form">
        <label>Pay Rate </label>
        <v-text-field
          label="Rate"
          disabled
          v-model="form.rate"
          maxlength="50"
        ></v-text-field>
      </div>
      <div
        class="form-group col-md-6 mb-form"
        v-if="editTimeTaker.timeEditReason"
      >
        <label>Reason for time adjustment</label>
        <v-text-field
          label="Rate"
          disabled
          v-model="editTimeTaker.timeEditReason"
        ></v-text-field>
      </div>
      <div
        class="form-group col-md-6 mb-form d-flex"
        style="flex-direction: column"
        v-if="editTimeTaker.signature != null"
      >
        <label>Parent/Guardian Signature</label>
        <img
          :src="'data:image/jpeg;base64,' + editTimeTaker.signature"
          style="width: 150px; border: 2px solid"
        />
      </div>

      <div class="form-group col-md-3 mb-form"></div>
    </v-row>
    <v-divider></v-divider>
    <span>Enter any notes for this time entry.(Optional)</span>
    <v-col cols="12" sm="12">
      <v-textarea
        filled
        auto-grow
        label="Notes"
        maxlength="250"
        v-model="form.notes"
        rows="4"
        row-height="30"
        shaped
      ></v-textarea>
    </v-col>
    <v-row class="float-right">
      <v-col
        cols="12"
        :sm="
          GetAuthenticatedUser.userType == 'Admin Level 2' ||
          GetAuthenticatedUser.userType == 'JFS' ||
          GetAuthenticatedUser.userType == 'Home Health' ||
          GetAuthenticatedUser.userType == 'Super Admin'
            ? '4'
            : '6'
        "
      >
        <v-btn @click="cancel"> Cancel </v-btn></v-col
      >
      <v-col
        cols="12"
        sm="4"
        v-if="
          !this.form.status.includes('Paid') &&
          (GetAuthenticatedUser.userType == 'Admin Level 2' ||
            GetAuthenticatedUser.userType == 'Super Admin' ||
            GetAuthenticatedUser.userType == 'Home Health' ||
            GetAuthenticatedUser.userType == 'JFS')
        "
      >
        <v-btn class="btn btn-danger" @click="deleteData">
          Delete
          <span v-if="isDel">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span> </v-btn
      ></v-col>
      <v-col
        cols="12"
        v-if="!this.form.status.includes('Paid')"
        :sm="
          GetAuthenticatedUser.userType == 'Admin Level 2' ||
          GetAuthenticatedUser.userType == 'Super Admin' ||
          GetAuthenticatedUser.userType == 'Home Health' ||
          GetAuthenticatedUser.userType == 'JFS'
            ? '4'
            : '6'
        "
      >
        <v-btn
          :disabled="isApproved"
          class="btn btn-success text-white"
          @click="updateData"
        >
          Update
          <span v-if="isSubmitting">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </span> </v-btn
      ></v-col>
    </v-row>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { ModelListSelect } from "vue-search-select";
export default {
  components: {
    ModelListSelect,
  },
  watch: {
    subCodeId(val) {
      this.changeSubCode(val);
    },
  },
  data: () => ({
    ReminderPickerOptions: {
      showTodayButton: true,
      showClear: true,
      showClose: true,
      icons: {
        time: "far fa-clock",
        up: "fas fa-arrow-up",
        down: "fas fa-arrow-down",
        previous: "fas fa-chevron-left",
        next: "fas fa-chevron-right",
        clear: "far fa-trash-alt",
        close: "far fa-times-circle",
      },
      sideBySide: false,
    },
    isApproved: false,
    isSubmitting: false,
    isDel: false,
    subCodeId: null,
    authno: null,
    form: {
      startDate: null,
      endDate: null,
      modifiedBy: "",
    },
  }),
  computed: {
    ...mapGetters([
      "editTimeTaker",
      "subcodeListClient",
      "GetAuthenticatedUser",
      "serviceList",
    ]),
  },
  methods: {
    dobformat(obj) {
      if (obj != null) {
        return new Date(obj);
      }
    },
    changeStatus() {
      Swal.fire({
        icon: "warning",
        text: "Are you sure you want to Approve this Timesheet",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
      }).then((e) => {
        if (e.isConfirmed) {
          this.form.status = "Approved By Admin";
        }
      });
    },
    changeSubCode(obj) { 
      var list = this.serviceList[0].casegiverAssociatedSubcodes.filter((e) => {
        return e.subCodesId == obj && e.authorizationNumber == this.authno;
      }); 
      if (list.length > 0) {
        this.form.rate = list[0].payRate;
      }
    },
    onfocusin() {},

    updateData() {
      this.form.subCodeId = this.subCodeId;

      delete this.form.subCode;
      this.isSubmitting = true;
      this.form.startTimeDate = this.dobformat(this.form.startTimeDate);
      this.form.endTimeDate = this.dobformat(this.form.endTimeDate);

      // this.form.startTimeDate = new Date(this.form.startTimeDate);
      // this.form.startTimeDate.setHours(this.form.startTimeDate.getHours() + 6);
      // this.form.endTimeDate = new Date(this.form.endTimeDate);
      // this.form.endTimeDate.setHours(this.form.endTimeDate.getHours() + 6);

      let sDate = new Date(
        new Date(
          this.form.editStartTimeDate != null
            ? this.form.editStartTimeDate
            : this.form.startTimeDate
        ).toLocaleDateString() +
          " " +
          new Date(
            this.form.editStartTimeDate != null
              ? this.form.editStartTimeDate
              : this.form.startTimeDate
          ).toLocaleTimeString()
      );

      var sUtcDate = new Date(
        sDate.getTime() - sDate.getTimezoneOffset() * 60000
      );

      this.form.editStartTimeDate = sUtcDate;
      let eDate = new Date(
        new Date(
          this.form.editEndTimeDate != null
            ? this.form.editEndTimeDate
            : this.form.endTimeDate
        ).toLocaleDateString() +
          " " +
          new Date(
            this.form.editEndTimeDate != null
              ? this.form.editEndTimeDate
              : this.form.endTimeDate
          ).toLocaleTimeString()
      );

      var eUtcDate = new Date(
        eDate.getTime() - eDate.getTimezoneOffset() * 60000
      );

      this.form.editEndTimeDate = eUtcDate;

      let diffInMilliSeconds =
        Math.abs(this.form.editEndTimeDate - this.form.editStartTimeDate) /
        1000;

      this.form.actualHours = Math.floor(diffInMilliSeconds / 3600) % 24;
      this.form.modifiedBy = this.GetAuthenticatedUser.userName;
      if (this.form.editEndTimeDate - this.form.editStartTimeDate >= 0) {
        this.$store.dispatch("updateTimeTracker", this.form).then((res) => {
          this.isSubmitting = false;
          if (res.status) {
            Swal.fire({
              title: "",
              text: "This Time Entry has been updated",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            this.$emit("updateData");
          } else {
            this.form.startTimeDate = new Date(this.form.startTimeDate);
            this.form.startTimeDate.setHours(
              this.form.startTimeDate.getHours() - 5
            );
            this.form.endTimeDate = new Date(this.form.endTimeDate);
            this.form.endTimeDate.setHours(
              this.form.endTimeDate.getHours() - 5
            );
            this.$emit("updateData");
            Swal.fire({
              title: "",
              text: res.message,
              icon: "error",
              confirmButtonClass: "btn btn-danger",
            });
            this.$emit("updateData");
          }
        });
      } else {
        this.isSubmitting = false;
        this.form.startTimeDate = new Date(this.form.startTimeDate);
        this.form.startTimeDate.setHours(
          this.form.startTimeDate.getHours() - 5
        );
        this.form.endTimeDate = new Date(this.form.endTimeDate);
        this.form.endTimeDate.setHours(this.form.endTimeDate.getHours() - 5);
        Swal.fire({
          title: "",
          text: "End Time should be greater then Start Time",
          icon: "error",
          confirmButtonClass: "btn btn-danger",
        });
      }
    },

    deleteData() {
      this.isDel = true;
      Swal.fire({
        icon: "warning",
        text: "Are you sure you want to Delete this Timesheet",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showCancelButton: true,
      }).then((e) => {
        if (e.isConfirmed) {
          this.$store.dispatch("removeTimeTracker", this.form.id).then(() => {
            this.isDel = false;
            Swal.fire({
              title: "",
              text: "You have deleted this time entry successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
            this.isDel = false;
            this.$emit("updateData");
          });
        }
      });
    },
    cancel() {
      this.$emit("updateData");
    },
    dobformatactual(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear() +
          " " +
          date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
        );
      }
    },
  },
  mounted() {
    if (this.editTimeTaker) { 
      this.form = this.editTimeTaker;
      this.form.subCode = `${this.form.subCode} - ${this.form.authorizationNumber}`;
      this.form.modifiedBy = this.GetAuthenticatedUser.userName;
      this.form.startTimeDate = this.dobformatactual(this.form.startTimeDate);
      this.form.endTimeDate = this.dobformatactual(this.form.endTimeDate);
      this.form.editStartTimeDate = this.dobformatactual(
        this.form.editStartTimeDate != null
          ? this.form.editStartTimeDate
          : this.form.startTimeDate
      );
      this.form.editEndTimeDate = this.dobformatactual(
        this.form.editEndTimeDate != null
          ? this.form.editEndTimeDate
          : this.form.endTimeDate
      );

      this.isApproved = this.form.status.includes("Rejected")
        ? true
        : this.form.status.includes("In Progress")
        ? true
        : false; 
      this.subCodeId = this.form.subCodeId;
      this.authno = this.form.authorizationNumber;
    }
  },
};
</script>
